import { useIntercom } from "react-use-intercom";
import { msalInstance } from "../../pages/_app";
import { useEffect } from "react";
import { useGetAuthorizedIntercomKeyLazyQuery } from "../../graphql/generated/graphql";

export const useBootIntercom = () => {
  const { boot } = useIntercom();
  const account = msalInstance?.getActiveAccount();
  const oid = account?.localAccountId;
  const username = account?.username;

  const [getIntercomKey, { data }] = useGetAuthorizedIntercomKeyLazyQuery();

  useEffect(() => {
    getIntercomKey().then(() => {
      if (data) {
        boot({
          email: username ? username : "",
          name: convertEmailToName(username),
          userId: oid,
          userHash: data.authorizedIntercomKey,
        });
      }
    });
  }, [getIntercomKey, data]);
};

export const convertEmailToName = (email?: string): string => {
  if (!email) return "Guest";

  const emailParts = email.split("@");
  const namePart = emailParts[0];

  const nameParts = namePart.split(".");

  const formattedName = nameParts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");

  return formattedName;
};
